// extracted by mini-css-extract-plugin
export var cover = "index-module--cover--327cc";
export var animatedBackground = "index-module--animatedBackground--3cb70";
export var coverapartment = "index-module--coverapartment--d17f0";
export var coverHidden = "index-module--coverHidden--6c4e5";
export var poolDesign = "index-module--pool-design--9e4ca";
export var textclass = "index-module--textclass--fbe0e";
export var maintext = "index-module--maintext--6e404";
export var datePickerContainer = "index-module--datePickerContainer--e4feb";
export var mapBox = "index-module--map-box--b33af";
export var heartColor = "index-module--heartColor--b2549";
export var treeColor = "index-module--treeColor--6ab87";
export var lightColor = "index-module--lightColor--4530c";
export var calendarMainColor = "index-module--calendarMainColor--5c55e";
export var bottomOfCover = "index-module--bottomOfCover--b02c4";