import * as React from 'react'
import Layout from "../components/layout/layout";
import {graphql} from "gatsby";
import {coverapartment} from "./index.module.scss";
import {space} from "./apartment.module.scss";
import {Trans, useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faWifi,
    faTv,
    faHotel,
    faBed,
    faCoffee,
    faParking,
    faHome,
    faMountain
} from "@fortawesome/free-solid-svg-icons";

const ApartmentPage = () => {
    const {t} = useTranslation();
    return (
        <Layout pageTitle={t('pages.apartment.title')}>
            <div className={coverapartment}/>
            <div className="spaceBlock"/>
            <div className="spaceBlock"/>

            <div className="background-info">
                <div className="container">
                    <div className={space}></div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-2">
                                    <h3><FontAwesomeIcon icon={faWifi}/></h3>
                                </div>
                                <div className="col-md-10">
                                    <h3><Trans>pages.apartment.features.optical</Trans></h3>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-2">
                                    <h3><FontAwesomeIcon icon={faBed}/></h3>
                                </div>
                                <div className="col-md-10">
                                    <h3><Trans>pages.apartment.features.bedroom</Trans></h3>
                                </div>
                            </div>
                        </div>

                        <div className={space}></div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-2">
                                    <h3><FontAwesomeIcon icon={faTv}/></h3>
                                </div>
                                <div className="col-md-10">
                                    <h3><Trans>pages.apartment.features.cabletv</Trans></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-2">
                                    <h3><FontAwesomeIcon icon={faHotel}/></h3>
                                </div>
                                <div className="col-md-10">
                                    <h3><Trans>pages.apartment.features.elevator</Trans></h3>
                                </div>
                            </div>
                        </div>

                        <div className={space}></div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-2">
                                    <h3><FontAwesomeIcon icon={faCoffee}/></h3>
                                </div>
                                <div className="col-md-10">
                                    <h3><Trans>pages.apartment.features.espresso</Trans></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-2">
                                    <h3><FontAwesomeIcon icon={faParking}/></h3>
                                </div>
                                <div className="col-md-10">
                                    <h3><Trans>pages.apartment.features.parking</Trans></h3>
                                </div>
                            </div>
                        </div>
                        <div className={space}></div>

                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-2">
                                    <h3><FontAwesomeIcon icon={faHome}/></h3>
                                </div>
                                <div className="col-md-10">
                                    <h3><Trans>pages.apartment.features.fireplace</Trans></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-2">
                                    <h3><FontAwesomeIcon icon={faMountain}/></h3>
                                </div>
                                <div className="col-md-10">
                                    <h3><Trans>pages.apartment.features.view</Trans></h3>
                                </div>
                            </div>
                        </div>

                        <div className={space}></div>

                    </div>
                </div>
            </div>

        </Layout>
    )
}
export default ApartmentPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
